<template>
  <div id="app">
    <index />
  </div>
</template>

<script>
import Index from "./pages/index/index"
export default {
  name: 'App',
  components: {
    Index: Index
  }
}
</script>

<style>
@import "static/css/app.css";
</style>