<template>
  <div class="footer-left">
    <dv-charts :option="options" style="height: 390px;width: 650px" />
  </div>
</template>

<script>
export default {
  name: "footerLeft",
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      options: {}
    }
  },
  created() {
    this.setData()
  },
  mounted() {
  },
  watch: {
    data() {
      this.setData();
    }
  },
  methods: {
    setData() {
      let data = [];
      let names = [];
      let max = 0;
      this.data.map(item => {
        data.push(item.value);
        names.push(item.name);
        if (max < item.value) {
          max = item.value;
        }
      })
      let config = {
        title: {
          text: '按行业分类的质量服务需求量（单位：家）',
          style: {
            fill: '#fff',
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        grid: {
          left: 200
        },
        xAxis: {
          data: 'value',
          min: 0,
          max: max,
          splitLine: {
            show: false
          },
          axisLabel: {
            style: {
              fill: '#fff'
            }
          }
        },
        yAxis: {
          data: names,
          splitLine: {
            show: false
          },
          axisLabel: {
            style: {
              fontSize: 12,
              fill: '#fff',
              rotate: 20
            }
          }
        },
        series: [
          {
            data: data,
            type: 'bar',
            label: {
              show: true,
              offset: [10, 0],
              style: {
                fill: '#fff',
                fontSize: 12
              }
            },
            gradient: {
              color: ['#37a2da', '#67e0e3']
            }
          }
        ]
      };
      this.options = config;
    }
  }
}
</script>

<style scoped>
.footer-left {
  padding-left: 20px;
}
</style>