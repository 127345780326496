<template>
  <div class="left-top">
    <div class="chart-name">
      质量服务需求类别统计
      <dv-decoration-1 style="width:200px;height:20px;" />
      <div class="chart-name-text">已提交需求调查问卷表总数：{{count}}（份）</div>
    </div>
    <dv-capsule-chart :config="options" style="height:260px" />
  </div>
</template>

<script>
export default {
  name: "leftTop",
  props: {
    count: {
      type: [Number, String],
      default: ''
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      options: {}
    }
  },
  created() {
    this.setData();
  },
  watch: {
    data() {
      this.setData();
    }
  },
  methods: {
    setData() {
      let options = {
        data: this.data,
        // colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '个',
        showValue: true,
      }
      this.options = options;
    }
  }
}
</script>

<style scoped>
.left-top {
  position: relative;
  padding-top: 60px;
}
.chart-name {
  position: absolute;
  left: 10px;
  right: 0;
  text-align: left;
  font-size: 16px;
  top: 10px;
}
.chart-name-text {
  position: absolute;
  font-size: 12px;
  left: 220px;
  top: 20px;
}
</style>