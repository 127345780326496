<template>
  <div class="right-top">
    <div class="chart-name">
      按市场主体类型分类的质量服务需求量（单位：个）
      <dv-decoration-1 style="width:200px;height:20px;" />
    </div>
<!--    <dv-charts :option="options" style="height:400px;width: 500px" />-->
    <dv-capsule-chart :config="options" style="height:260px;" />
  </div>
</template>

<script>
  export default {
    name: "rightTop",
    props: {
      data: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        options: {},
        show: false
      }
    },
    created() {
      this.setData();
    },
    beforeDestroy() {
    },
    watch: {
      data() {
        this.setData();
      }
    },
    methods: {
      setData() {
        let options = {
          data: this.data,
          // colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
          unit: '个',
          showValue: true,
        }
        this.options = options;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .right-top {
    position: relative;
    padding-top: 60px;
  }
  .chart-name {
    position: absolute;
    left: 120px;
    text-align: left;
    font-size: 16px;
    top: 10px;
  }
</style>