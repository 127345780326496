<template>
  <div class="center">
    <div class="chart-name">
      需求提交周趋势
      <dv-decoration-3 style="width:200px;height:20px;" />
    </div>
    <dv-charts :option="options" style="height: 400px;width: 600px" />
  </div>
</template>

<script>
export default {
  name: "center",
  props: {
    map: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      options: {},
      loopTimerId: null
    }
  },
  created() {
    this.getList();
  },
  beforeDestroy() {
    if (this.loopTimerId) {
      clearInterval(this.loopTimerId);
    }
  },
  watch: {
    map() {
      clearInterval(this.loopTimerId);
      this.getList()
    }
  },
  methods: {
    getList() {
      this.$ajax.get('/statistics/demand_company_city/get7Day', {params: this.map}).then(res => {
        this.setConfig(res);
      }).finally(() => {
        this.loopTimerId = setTimeout(() => {
          this.getList();
        }, 6000)
      })
    },
    setConfig(res) {
      let options = {
        legend: {
          data: [{
            name: '质量服务需求量',
            color: '#3de7c9'
          },
            {
            name: '问卷数量',
            color: '#f5d94e',
          }],
          textStyle: {
            fill: '#fff'
          }
        },
        xAxis: {
          data: res.time,
          boundaryGap: true,
          axisLine: {
            style: {
              stroke: '#666'
            }
          },
          axisLabel: {
            style: {
              fill: '#ffffff',
              fontSize: 12
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          data: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            style: {
              stroke: '#999'
            }
          },
          axisLabel: {
            style: {
              fill: '#999'
            },
            formatter ({ value }) {
              return value.toFixed(2)
            }
          },
          axisTick: {
            show: false
          },
          splitNumber: 10,
          min: -10,
          // max: Math.ceil(res.max / 10) * 10
        },
        series: [
          {
            data: res.data,
            type: 'line',
            name: '质量服务需求量',
            smooth: true,
            label: {
              show: true,
              offset: [0, -15],
              style: {
                fontSize: 14,
                fill: '#fff'
              }
            },
            lineArea: {
              show: true,
              gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
            },
            linePoint: {
              radius: 4,
              style: {
                fill: '#00db95'
              }
            }
          },
          {
            data: res.all,
            type: 'line',
            name: '问卷数量',
            smooth: true,
            label: {
              show: true,
              offset: [10, -15],
              style: {
                fontSize: 14,
                fill: '#f5d94e'
              }
            },
            linePoint: {
              radius: 4,
              style: {
                fill: '#f5d94e',
                stroke: 'transparent'
              }
            },
            lineStyle: {
              stroke: '#f5d94e',
              lineDash: [5, 5]
            },
          }
        ]
      };

      this.options = options;
    }
  }
}
</script>

<style scoped>
.center {
  position: relative;
  padding-left: 50px;
}
.chart-name {
  position: absolute;
  right: 70px;
  text-align: right;
  font-size: 20px;
  top: 10px;
}
</style>